.main {
    :global {
        .jobStatusBar {
            margin-top: -1rem;
            margin-bottom: 1rem;
        }

        .detailsTable {
            .status {
                text-align: center;
                border-radius: 1rem;
                padding: 0.3rem;
                background-color: #eeeeee;
                border: 1px solid #000;

                &.resolved {
                    background-color: #fbfef1;
                    border-color: #4a7729;
                }

                &.open {
                    background-color: #fff5f7;
                    border-color: #df0404;
                }
            }
        }
    }
}
