@use '../../components/icons';
@use '../../components/colors';

.main {
    margin-left: 0;

    :global {
        .messageDetailIcon {
            padding-left: 0;
            padding-right: 0.5rem;
        }

        p {
            padding-left: 0;
            font-size: 0.9rem;
        }
    }
}
