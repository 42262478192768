@use '../../../components/icons';
@use '../../../components/colors';

.main {
    button {
        background-color: transparent;
        color: colors.$white;
        border: 1px solid transparent;

        &:disabled {
            color: colors.$grey3;
        }

        &:not(:disabled) {
            &:hover,
            &:active,
            &:focus {
                background-color: transparent;
                color: colors.$white;
                border: 1px dotted colors.$grey4;
            }
        }

        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: auto;
    }
}

.flyout {
    :global(.offcanvas-header) {
        background-color: colors.$grey2;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.menu {
    font-size: 0.9rem;

    :global(.dropdown-item-text) {
        padding-top: 0;
        padding-bottom: 0;
    }

    a,
    span {
        font-size: 0.9rem;
        line-height: 1.25rem;
    }

    hr {
        opacity: 100;
        border-top: 1px solid colors.$grey3;
    }
}
