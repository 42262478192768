.main {
    margin-top: 0.25rem;
    font-size: 0.9rem;

    a {
        font-size: 0.9rem;
    }
    td,
    th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
        vertical-align: top;

        &:global(.normalWidth) {
            min-width: 12rem;
        }

        &:first-child {
            padding-left: 0.25rem;
        }

        &:last-child {
            padding-right: 0.25rem;
        }
    }
}
