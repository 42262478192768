@use '../../../../../../components/colors';

.main {
    border: 2px solid #770000;
    padding: 1rem;
}

.error {
    margin-top: 0.75rem;
    padding: 1rem;
    text-align: center;
    color: #770000;
    border: 2px solid #770000;
    background-color: #ffcccc;

    p:last-child {
        margin-bottom: 0;
    }
}
