.main {
    :global {
        .jobStatusBar {
            position: relative;
            width: 100%;
            height: 0.75rem;

            margin: 0;
            padding: 0;

            background-color: #dddddd;

            &.nonOutage {
                background: #5cad5c;

                &.open {
                    background: #ad5c5c;
                }
            }

            &.outage {
                background: #006600;

                &.open {
                    background: #550000;
                }
            }
        }
    }
}
