@use '../../../../../../components/colors';

.main {
    hr {
        margin: 0.5rem 0;
    }
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.premiseId {
    font-size: 0.9rem;
}
