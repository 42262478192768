.main {
    position: relative;
    margin-bottom: 1rem;

    :global {
        .subheader {
            font-size: 0.75rem;
            font-weight: normal;

            span {
                display: inline-block;
            }

            .type {
                width: 2rem;
            }

            .value {
                width: 7rem;
            }
        }
    }
}

.subheader {
    font-size: 0.75rem;
    font-weight: normal;

    :global {
        span {
            display: inline-block;
        }

        .open {
            font-weight: 600;
        }

        .type {
            width: 4rem;
        }

        .value {
            width: 7rem;
        }
    }
}

.dayDivider {
    font-weight: 600;
    font-size: 0.9rem;
    text-align: center;
}

.heading {
    text-align: center;
    font-weight: 600;
    margin-bottom: 1rem;
    color: inherit;
    color: #222;
}

.timeline {
    :global {
        .vertical-timeline {
            padding: 0.25rem 0;
        }

        .future {
            .vertical-timeline-element-date {
                font-weight: 600;
            }
        }

        .clickable {
            .vertical-timeline-element-icon,
            .vertical-timeline-element-content {
                cursor: pointer;
            }
        }

        .vertical-timeline-element-icon {
            margin-left: -0.25rem;
        }

        // @media only screen and (min-width: 1170px) {
        @media only screen and (min-width: 767px) {
            .vertical-timeline--two-columns {
                width: 98%;

                @media only screen and (min-width: 930px) {
                    width: 94%;
                }

                @media only screen and (min-width: 1170px) {
                    width: 90%;
                }

                &:before {
                    left: 50%;
                    margin-left: -2px;
                }

                .vertical-timeline-element-date {
                    position: absolute;
                    width: 100%;
                    left: 127%;
                    top: 0.5rem;

                    @media only screen and (min-width: 930px) {
                        left: 122%;
                    }

                    @media only screen and (min-width: 1170px) {
                        left: 118%;
                    }
                }

                .vertical-timeline-element-content-arrow {
                    top: 1rem;
                    left: 100%;
                    transform: rotate(180deg);
                }

                .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
                    .vertical-timeline-element-content-arrow,
                .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content-arrow {
                    top: 1rem;
                    left: auto;
                    right: 100%;
                    transform: rotate(0deg);
                }

                .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
                    .vertical-timeline-element-content,
                .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content {
                    float: right;
                }

                .vertical-timeline-element.vertical-timeline-element--right
                    .vertical-timeline-element-content
                    .vertical-timeline-element-date,
                .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
                    .vertical-timeline-element-content
                    .vertical-timeline-element-date {
                    left: auto;
                    right: 127%;
                    text-align: right;

                    @media only screen and (min-width: 930px) {
                        right: 122%;
                    }

                    @media only screen and (min-width: 1170px) {
                        right: 118%;
                    }
                }

                .vertical-timeline-element-icon {
                    margin-left: -1.5rem;
                    left: 50%;
                }

                .vertical-timeline-element-content {
                    width: 44%;
                    margin-left: 0;

                    @media only screen and (min-width: 930px) {
                        width: 45%;
                    }

                    @media only screen and (min-width: 1170px) {
                        width: 46%;
                    }
                }
            }
        }

        h5 {
            font-weight: 400;
            font-size: 1rem;
            padding-bottom: 0.25rem;
        }

        .details {
            font-size: 0.75rem;
        }

        .vertical-timeline-element {
            margin: 1rem 0;
        }

        .vertical-timeline-element-content {
            box-shadow: 0rem 0rem 0.3rem #555;
            padding: 0.75rem;

            .vertical-timeline-element-content-arrow {
                top: 1rem !important;
            }
        }

        .vertical-timeline-element-date {
            color: #505357;
            padding-top: 0.25rem;
            padding-bottom: 0;
        }

        .vertical-timeline-element-icon {
            background-color: #fff;
            box-shadow: 0 0 0.5rem #555;
            width: 3rem;
            height: 3rem;
            display: grid;
            place-items: center;

            svg {
                width: 1.5rem;
                height: auto;
                margin-left: 0;
                margin-top: 0;
                top: auto;
                left: auto;

                &.resolved {
                    color: #4a7729;
                }
            }
        }

        .active {
           .vertical-timeline-element-content {
                box-shadow: #007bff 0 0 0.3rem 0;
            }

            .vertical-timeline-element-icon {
                background-color: #007bff;
                svg {
                    color: #fff;
                }
           }
        }
    }
}
