.check {
    &:first-of-type {
        margin-top: 0;
    }
    label {
        font-weight: normal;
        width: 100%;
    }
    margin-top: 0.5rem;

    &:global(.form-check-inline) {
        label {
            min-width: 5rem;
        }
        margin-top: 0;
    }
}