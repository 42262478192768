.main {
    :global {
        .iconRow {
            margin: 1rem;
        }

        .iconRow.row {
            align-items: center;
        }

        .errorIcon {
            cursor: pointer;
        }
    }
}

.errorTooltip {
    :global {
        .popover-header {
            background-color: #660000;
            color: #fff;
            font-weight: 600;
        }
    }
}
