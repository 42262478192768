$brandBlue: #1e3575;
$brandOrange: #e96316;
$activeBlue: #0072ce;
$black: #2b2b2b;
$grey4: #505357;
$grey3: #73767b;
$grey2: #b1b3b3;
$grey1: #d9d9d9;
$grey0: #f5f5f5;
$white: #ffffff;
$primitive-lightPurple: #c1a0da;
$primitive-purple: #6f5091;
$primitive-darkPurple: #523178;
$lightGreen: #b5cf83;
$primitive-lightGreen: #d2d755;
// Adjusted slightly darker than the official primitive-green to meet WCAG contrast standards
$primitive-green: #839f14;
$primitive-darkGreen: #4a7729;

$primitive-darkAqua: #006269;
$primitive-aqua: #007c91;
$primitive-lightAqua: #8dc8e8;

$inverse-interactive: #8dc8e8;

$content-negative: #e31937;
$brown: #765b21;
$lightBrown: #c8b268;
